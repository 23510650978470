<template>
    <div class="detail">
        <van-field label="机构名" rows="1" type="textarea" autosize :value="dataJson.org_name" readonly />
        <van-field label="签章次数" rows="1" type="textarea" autosize :value="dataJson.settlement_quantity" readonly />
        <van-field label="总费用" rows="1" type="textarea" autosize :value="dataJson.settlement_fee>0?'¥'+Number(dataJson.settlement_fee).formatMoney(): '¥ 0.00'" readonly />
    </div>
</template>

<script>

import { Field } from 'vant';
export default {
    name: "DetailBox",
    props: {
        dataJson: Object,
    },
    components:{
        [Field.name]: Field,
    }
}
</script>
<template>
    <div class="detail">
        <van-field :label="nameVal == 1?'律所名':'机构名'" rows="1" type="textarea" autosize :value="dataJson.deptName" readonly />
        <van-field label="机构码" rows="1" type="textarea" autosize :value="dataJson.deptCode" readonly />
        <van-field label="案件数" rows="1" type="textarea" autosize :value="dataJson.count" readonly />
        <van-field label="诉讼总金额" rows="1" type="textarea" autosize :value="dataJson.prosecutionMoney > 0 ? '¥'+ dataJson.prosecutionMoney.formatMoney():'¥ 0.00'" readonly />
        <van-field label="系统服务费用" rows="1" type="textarea" autosize :value="dataJson.feeMoney > 0 ? '¥'+ dataJson.feeMoney.formatMoney():'¥ 0.00'" readonly />
        <van-field label="基础费用" rows="1" type="textarea" autosize :value="dataJson.baseFee > 0 ? '¥'+ dataJson.baseFee.formatMoney(): '¥ 0.00'" readonly />
        <van-field label="流量费用" rows="1" type="textarea" autosize :value="dataJson.flowFee > 0 ?'¥'+dataJson.flowFee.formatMoney(): '¥ 0.00'" readonly />
        <van-field label="结算时间" rows="1" type="textarea" autosize :value="dataJson.creatorTime" readonly />
    </div>
</template>

<script>

import { Field } from 'vant';
export default {
    name: "DetailBox",
    props: {
        dataJson: Object,
        nameVal:Number
    },
    components:{
        [Field.name]: Field,
    }
}
</script>
<template>
    <div class="detail">
        <van-field label="法院名" rows="1" type="textarea" autosize :value="dataJson.orgName" readonly />
        <van-field label="机构码" rows="1" type="textarea" autosize :value="dataJson.orgCode" readonly />
        <van-field label="系统服务费" rows="1" type="textarea" autosize :value="dataJson.platformFee > 0 ? '¥'+ dataJson.platformFee.formatMoney():'¥ 0.00'" readonly />
        <van-field label="服务时间" rows="1" type="textarea" autosize :value="dataJson.serviceTime" readonly />
        <van-field label="到期时间" rows="1" type="textarea" autosize :value="dataJson.serviceTimeExpire" readonly />
        <van-field label="创建时间" rows="1" type="textarea" autosize :value="dataJson.creatorTime" readonly />
        <van-field label="收款条件" class="text-style" rows="1" type="textarea" autosize :value="dataJson.paymentCondition" readonly />
    </div>
</template>

<script>

import { Field } from 'vant';
export default {
    name: "DetailBox",
    props: {
        dataJson: Object,
    },
    components:{
        [Field.name]: Field,
    }
}
</script>